.action-icon-container {
  font-size: larger;
  justify-content: center;

  .action-icon-positive {
    color: #485cc7;
    font-size: 23px;
  }
  .action-icon-negative {
    color: #ff5555;
    font-size: 23px;
  }
}