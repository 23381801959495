@each $name, $glyph in $social-colors {
  .bg-#{$name} {
    background-color: $glyph !important;
  }

  .color-#{$name}, .text-#{$name}  {
    color: $glyph !important;
  }

}
