.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #FDFFFF !important;
  background: #4195B2B0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FDFFFF !important;
}

.ant-tabs-tabpane {
  border-style: ridge;
  background-color: white;
  height: 700px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: white;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 0 0;
}