.main {
  width: 100%;
  padding-left: 0;
  font-size: $font-size-base;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #f0f2f5;

  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.1);
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(0,0,0,0.2);
    :hover {
      background: rgba(0,0,0,0.4);
    }
    :active {
      background: rgba(0,0,0,.9);
    }
  }
}

.ant-input {
  font-size: $font-size-base;
  //height: 40px;
}

.ant-input-number-input {
  font-size: $font-size-base;
  //height: 40px;
}

.ant-card {
  font-size: $font-size-base;
}

.ant-card-head {
  font-size: $headings-font-weight;
}

.ant-modal-title {
  font-size: $headings-font-weight;
}

.ant-menu {
  font-size: $font-size-base;
}

.ant-table {
  font-size: $font-size-base;
}

//.ant-btn:not(.ant-modal-footer > .ant-btn) {
//  font-size: $font-size-base;
//  height: 40px;
//}

.ant-btn {
  font-size: $font-size-base;
  border-radius: 2px;
}

.ant-modal-content > .ant-modal-footer > .ant-btn {
  width: 89px;
}

.ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-btns > .ant-btn {
  width: 89px;
}


//.ant-input-search-button {
//  height: 40px;
//}

.ant-picker-input > input {
  font-size: $font-size-base;
}

.ant-checkbox + span {
  font-size: $font-size-base;
}

.ant-select {
  font-size: $font-size-base;
}

.ant-tabs {
  font-size: $font-size-base;
}

.ant-tabs-tab-btn {
  font-size: $font-size-base;
}

.ant-dropdown-menu-item {
  font-size: $font-size-base;
}

.ant-table-pagination.ant-pagination {
  font-size: $font-size-base;
  margin-top: 15px;
}

img {
  pointer-events: none;
}

.optionContainer .highlight {
  background: white;
  color: black;
}
