.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.hover-pointer {
    &:hover {
        cursor: pointer !important;
        background-color: rgba(255, 255, 255, .1);
    }
}

.hover-grab {
    &:hover {
        cursor: grab !important;
    }
}
