.custom-select {
  .label-custom {
    position: absolute;
    margin: 8px 15px;
    color: gray;
    justify-content: space-between;
  }

  .ant-select-item {
    font-size: $font-size-base;
  }
}

.optionContainer .highlight {
  background: white;
  color: black;
}

.ant-select-multiple .ant-select-selection-item {
  display: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state,
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  display: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px;
  background: rgba(1, 1, 1, 0);
}

.icon-custom {
  right: 0;
}

@media only screen and (max-width: $grid-breakpoints-lg) {
  .label-custom {
    width: 70% !important;
    font-size: $font-size-mobile;
    position: absolute;
    margin: 8px 15px;
    color: gray;
    justify-content: space-between;
  }

  .ant-select-item {
    font-size: $font-size-mobile;
  }
  .custom-select {
    .label-custom {
      width: 70% !important;
    }
  }

}

@media only screen and (max-width: $grid-breakpoints-lg) and (min-width: 768px) {
  .custom-select {
    .label-custom {
      width: 75% !important;
    }
  }
}
