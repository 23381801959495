/**
Config here
 */
$sidebar-width: 365px * 0.7;
$sidebar-background: #001529;
$navbar-height: 50px;
$transition-layout: all .3s;
$transition-layout-time: .3s;

// End config


$font-family: "Roboto", cursive !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-size-base: 14px !default;
$font-size-mobile: 15px !default;
$font-size-lg: 1rem !default;
$font-size-sm: .75rem !default;
$headings-font-weight: 15px !default;

$spacer: 1rem !default;

$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4.5),
        7: ($spacer * 6)
) !default;

// Grid breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
) !default;

$grid-breakpoints-xs: 0 !default;
$grid-breakpoints-sm: 576px !default;
//$grid-breakpoints-md: 768px !default;
$grid-breakpoints-md: 992px !default;
$grid-breakpoints-lg: 992px !default;
$grid-breakpoints-xl: 1200px !default;
$grid-breakpoints-xxl: 1400px !default;
$grid-breakpoints-xxxl: 1600px !default;

// Grid containers
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px
) !default;

// Social colors
$social-colors: (
        "facebook": #3b5998,
        "twitter": #1da1f2,
        "google": #dc4e41,
        "youtube": #f00,
        "vimeo": #1ab7ea,
        "dribbble": #ea4c89,
        "github": #181717,
        "instagram": #e4405f,
        "pinterest": #bd081c,
        "flickr": #0063dc,
        "bitbucket": #0052cc,
        "white": white,
        "warning": orange,
        "success": #52C41A,
        "danger": #e53935,
        "info": #00acc1,
        "indigo": #3c4dac,
        "primary": #1890FF
) !default;

$border-color: #DEE2E6 !default;

$navbar-header-text-color: #8c8c8c !default;

$scrollBar-colors: (
        "background": #F5F5F5,
        "bg-thumb": rgba(131, 131, 131, 0.12),
        "shadow-thumb": inset 0 0 6px rgba(124, 123, 123, 0.3)
) !default;

$modal-colors: (
        "background": #4699E4,
        "title-color": black
) !default;
