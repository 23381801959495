.ant-table {

  .ant-table-thead > tr > th {
    background: #2F75B5 !important;
    font-weight: bolder !important;
    text-align: center;
    Color: #ffffff;

    .anticon.anticon-caret-up.ant-table-column-sorter-up.active {
      color: rgb(255, 255, 255);
    }
    .anticon.anticon-caret-down.ant-table-column-sorter-down.active {
      color: rgb(255,255,255);
    }
    .ant-table-column-sorter.ant-table-column-sorter-full{
      color: rgba(255,255,255,0.25);
    }
    .ant-dropdown-trigger.ant-table-filter-trigger.active{
      color: rgb(255, 255, 255);
    }
    .ant-dropdown-trigger.ant-table-filter-trigger{
      color: rgba(255,255,255,0.25);
    }
  }

  th.ant-table-cell {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  td.ant-table-cell{
    padding: 2px 5px !important;
  }
}