.login_page {

  .logo-text-mb {
    color: black;
    display: block;
    font-weight: bold;
    font-size: 35px;
    text-align: center;
  }

  .logo-text {
    font-size: 25pt;
    text-align: center;
    margin: 0 10px;
    font-weight: 600;
  }

  .ant-btn {
    height: 40px;
  }
}

//Mobile
@media only screen and (max-width: $grid-breakpoints-md) {
  .login_page {
    .logo-text {
      display: none;
    }

    .logo-text-mb {
      color: black;
      display: block;
      font-weight: bold;
      font-size: 35px;
      text-align: center;
    }
  }
}