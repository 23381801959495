.navbar {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  padding: 5px 10px 5px 10px;
  @media (min-width: $grid-breakpoints-md) {
    padding: 10px 15px 10px 15px;
  }
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: $navbar-height;
  position: sticky;
  z-index: 999;

  .ant-breadcrumb-link {
    font-weight: 500;
    font-size: $font-size-base;
  }
  .nav-title{
    font-weight: 400;
    color: $navbar-header-text-color;
    font-size: 14px;
    line-height: 22px;
  }

  .group-user-info {
    @media (max-width: 600px) {
      display: none;
    }
  }
}

//Mobile
@media only screen and (max-width: $grid-breakpoints-md) {
  .navbar {
    .group-user-info {
      display: none;
    }
  }
}
