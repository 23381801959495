// Desktop
.top-page-header {
  border-bottom: 1px solid #000;
  display: inline;
}

.ant-btn-text[disabled], .ant-btn-text[disabled]:hover, .ant-btn-text[disabled]:focus, .ant-btn-text[disabled]:active {
  background-color: #f5f5f5 !important;
  color: #00000040 !important;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.top-page.p-3 {
  margin-top: 10px;

  .update-at {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 4px;
    background-color: #fff;
  }

  .top-page-row {
    right: 0;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  .btn-top-page, .update-at, .btn-top-refresh {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: white;
  }

  .btn-export-mobile {
    display: none;
  }

  .top-page-ok-ng-header-group, .top-page-env-header-group,
  .top-page-acc-header-group, th.top-page-mic-header {
    cursor: pointer;
  }

  .ant-table .ant-table-thead > tr {
    th.top-page-ok-ng-header-group:hover, th.top-page-env-header-group:hover,
    th.top-page-acc-header-group:hover, th.top-page-mic-header:hover {
      background: rgba(65, 149, 178, 0.6) !important;
    }
  }

  //Custom CSV checkbox
  .top-page-table > div > div > div > div > div > table > thead > tr:nth-child(1) > th.ant-table-cell.ant-table-selection-column > div > label > span > span:before {
    content: "CSV出力";
    top: -27px;
    left: -28px;
    position: absolute;
    width: 20px;
    font-size: $font-size-base;
  }

  .ant-table-selection {
    margin-top: 25px;
  }

  .top-page-table > div > div > div > div > div.ant-table-header > table > thead > tr:nth-child(1) > th.ant-table-cell.ant-table-selection-column > div > label > span > span {
    top: 10px;
    left: -3px;
  }

  div > div > div:nth-child(2) > div > div.ant-table-wrapper > div > div > div > div > div > table > thead > tr:nth-child(1) > th.ant-table-cell.ant-table-selection-column > div > label > span:after {
    display: none;
  }

  .top-page-table {
    width: 100vw;

    tr > th, {
      padding: 10px 28px !important;
    }
  }
}

.ant-checkbox-checked::after {
  visibility: hidden !important;
}

.ant-checkbox:hover:after {
  visibility: hidden !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox:after, .ant-checkbox:hover:after {
  visibility: hidden !important;
}

//Mobile
@media only screen and (max-width: $grid-breakpoints-md) {
  .top-page.p-3 {

    .top-page-table {
      scroll-behavior: smooth;
    }

    //Custom CSV checkbox
    .top-page-table > div > div > div > div > div.ant-table-header > table > thead > tr:nth-child(1) > th.ant-table-cell.ant-table-selection-column > div > label > span > span {
      left: -1px;
    }

    .ant-table-pagination-right {
      justify-content: space-between;
    }

    .top-page-row {
      justify-content: flex-start;
    }

    .btn-export-mobile {
      display: inline-block;
      top: -2px;
      padding: 2px 2px;
      //margin: 580px 2px;
    }

    .btn-export {
      display: none;
    }

    .top-page-table {
      tr > th, {
        padding: 4px 2px !important;
      }

      tr > td {
        padding: 3px 5px;
      }
    }


    .top-page-table {
      //table-layout: fixed;
      scroll-behavior: smooth;
    }

  }
}

