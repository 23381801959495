//.options {
//  .action-icon {
//    justify-content: center
//  }
//
//  .ant-btn {
//    font-weight: bold;
//  }
//}

.message-page {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
}

.setting-top-page {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
}
