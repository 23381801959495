.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.font-italic {
  font-style: italic;
}

.ant-card-head-title {
  font-weight: bold;
}

.ant-descriptions-item-label {
  font-weight: 500;
}
