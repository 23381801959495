#root {
  height: 100%;
}

.wrapperMain {
  min-height: 100%;
  width: 100%;
  display: flex;
  background-color: white;
}

.wrapperGraph {
  min-height: 100%;
  width: 100%;
  display: flex;
  background-color: #F0F2F5;
}
