.alert-page{
    .red{
        background-color: rgba(248,203,173,255); 
      }
    .yellow {
         background-color :rgba(255,242,204,255) ; 
      }
    // tr>td.ant-table-column-sort {
    //     background-color: unset ;
    // }
    td.ant-table-cell{
        border: 1px solid rgba(0, 0, 0, 0.06);
    }
    
}