.user-page {
  .btn-user {
    min-width: 95px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #D9D9D9;
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
  }
  .btn-user[disable] {
    background-color: #f5f5f5 !important;
    color: #00000040 !important;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }

}