.sidebar {
  background: #001529;
  height: 100%;
  width: $sidebar-width;
  position: fixed;
  top: 0;
  left: -$sidebar-width;
  transition: left $transition-layout-time;
  overflow: auto;

  z-index: 1001;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.open {
    left: 0;
    -webkit-box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.5);
  }

  .logo-container {
    padding: $spacer;
    display: flex;

    h1 {
      color: white;
    }
  }

  .sidebar-item {
    display: flex;
    align-items: center;
    margin: .5rem 0 .5rem 0;
    padding: 1rem 24px 1rem 24px;

    color: white !important;

    transition: background-color .3s;

    background: rgba(0, 0, 0, 0);

    &:hover {
      background-color: rgba(255, 255, 255, .1);
    }

    svg {
      width: 20px;
      height: 20px;
      color: white;
      margin-right: 15px;
      margin-left: 23px;
    }


  }
  .logout-item {
    height: 50px;
    width: 100%;
    padding: 1rem 24px 1rem 1rem;
    display: inline-flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      color: white;
      margin-right: 10px;
    }
    span {
      color: white;
      font-size: 20px;
    }
  }

  .ant-menu-item-selected {
    background-color: white !important;

    span {
      color: black !important;
    }

    svg {
      color: black !important;
    }
  }

  .ant-menu.ant-menu-dark {
    background-color: transparent;
  }

  .ant-menu-item {
    margin: 10px 0 !important;
    height: 50px !important;
  }

  .form-logout {
    .icon-logout {
      font-size: 20px;
      color: white;
    }
  }
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  visibility: hidden;
  transition: visibility $transition-layout-time, opacity $transition-layout-time;
  z-index: 1000;

  &:hover {
    cursor: pointer;
  }

  &.open {
    visibility: visible;
    opacity: .3;
  }
}

.logo-menu-text {
  font-size: 35pt;
  text-align: center;
  color: white;
  margin-left: 18px;
}

//Mobile
@media only screen and (max-width: $grid-breakpoints-md) {

  .sidebar {
    width: $sidebar-width*0.8;
    height: 100vh;

    .sidebar-item {
      span {
        font-size: 15px;
      }
    }

    .form-logout {
      display: block;
      bottom: 30px;
      flex-direction: row;
      text-align: center;
      margin-top: 50vh;
      font-size: 20px;
    }

    .sidebar-item-hidden {
      display: none !important;
    }
  }

  .logo-menu-text {
    font-size: 35px;
  }
}

@media only screen and (max-width: $grid-breakpoints-lg) and (min-width: 768px) {
  .sidebar {
    .form-logout {
      margin-top: 10vh;
    }
  }
}
