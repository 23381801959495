.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.8) !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: rgba(194, 201, 209, 1);
}

.ant-picker-range {
  border-radius: 5px;
}

.graph-page {
  .highcharts-credits {
    display: none;
  }

  .highcharts-title {
    display: none;
  }

  .ant-card-body {
    //font-size: 15px !important;
    height: 600px;
  }

  .title-graph {
    font-weight: bold;
  }

  .update-at {
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    height: 40px;
  }

  .btn-graph {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  .btn-export {
    width: 150px;
  }

  .action-graph {
    flex-direction: column;
    justify-content: flex-start;
  }

  .btn-check-box {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ant-select-multiple .ant-select-selection-item {
    //display: none;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    display: block;
    transition: top 0.1s, font-size 0.1s;
    overflow: unset;
  }

  .graph-all {
    width: 70%;
  }

  .icon-menu {
    display: none;
  }

  .btn-export-mb {
    display: none;
  }

}

@media only screen and (max-width: $grid-breakpoints-lg) {
  .graph-page {
    font-size: $font-size-mobile !important;

    .graph-all {
      width: 100%;

      .btn-rangePicker {
        display: none;
      }
    }

    .box-card-right {
      display: none;
    }

    .btn-export-mb {
      display: inline;
    }

    .icon-menu {
      display: inline;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      height: 35px !important;
    }

    .ant-btn {
      font-size: $font-size-mobile !important;
      //height: 40px;
    }
  }

  .ant-picker-panel-container {
    transform: scale(0.8, 0.8);
  }

  .ant-picker-input > input {
    font-size: $font-size-mobile !important;
  }

  .ant-checkbox + span {
    font-size: $font-size-mobile !important;
  }

  .ant-select {
    font-size: $font-size-mobile !important;
  }

  .ant-picker-dropdown-placement-bottomLeft {
    left: -42px !important;
    top: 22px !important;
    font-size: $font-size-mobile !important;
  }
  .custom-select-mb {

  }
}