@import "./login";

.auth-page-all {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;

  .forgot-pass {
    color: #1890ff;
  }

  .logo-text-auth {
    display: none;
  }

  .auth-page {
    min-height: 90vh;
    width: 100%;
    margin: 0;
    padding: 0;

    &:before {
      content: " ";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    .auth-card {
      border: 1px solid #ffffff;
      height: 250px;
      width: 350px;
      border-radius: 30px;

      .ant-card-body {
        height: 100%;
      }
    }

    .button-form {
      width: 100%;
      background-color: #1890FF;
    }
  }

  .ant-btn-primary:disabled {
    background: #00000040;
  }
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.logout-modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-btns {
  .ant-btn {
    width: 82px;
  }
}

.ant-tooltip-inner {
  max-height: 350px;
  width: fit-content;
  overflow: auto;
}

::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.5);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.3);

  :hover {
    background: rgba(0, 0, 0, 0.4);
  }

  :active {
    background: rgba(0, 0, 0, 0.9);
  }
}

//Mobile
@media only screen and (max-width: $grid-breakpoints-md) {
  .auth-page-all {

    .logo-text-auth {
      display: block;
      color: white !important;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }

    .auth-page {
      .auth-card {
        height: 300px;
        width: 300px;
      }
    }
  }
}

