.site-proj-management-page {
  .btn-site-proj-function {
    min-width: 95px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #D9D9D9;
    /* drop-shadow/button-secondary */
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 20px;
  }

  //.ant-select-arrow {
  //  top: 30%;
  //}
  //
  //.ant-select-clear {
  //  top: 30%;
  //}
}