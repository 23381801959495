.ant-modal {
  .ant-modal-body {
    font-size: $font-size-base;
  }

  .ant-modal-header {
    //background: #1890ff;
    .ant-modal-title {
      color: map-get($modal-colors, "title-color");
      font-size: 20px;
      //font-weight: bold;
    }
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: map-get($scrollBar-colors, "shadow-thumb");
    border-radius: 10px;
    background-color: map-get($scrollBar-colors, "background");
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: map-get($scrollBar-colors, "background");
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: map-get($scrollBar-colors, "shadow-thumb");
    background-color: map-get($scrollBar-colors, "bg-thumb");
  }

  //.ant-btn > span {
  //  font-weight: bold;
  //}

  .ant-input:read-only {
    border: none;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-size: $font-size-base;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: $font-size-base;
  }

  .ant-input-affix-wrapper {
    font-size: $font-size-base;
  }

  .ant-modal-footer {
    //background: rgba(196, 196, 196, 0.2);
  }
}
